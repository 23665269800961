import './commonAssets';

import '../common/javascripts/modules/member/MemberSubMembers.js';
import '../common/javascripts/modules/signup/JoinStep1BMT.js';
import '../common/javascripts/modules/survey/SurveyRedirect.js';

import '../common/modules/stock/collections/tabbed-stock-collections.less';
import '../common/stylesheets/maintenance.css';
import '../common/stylesheets/modules/collections/collection-browse.less';
import '../common/stylesheets/modules/details-uff.less';
import '../common/stylesheets/modules/member/submembers.less';
import '../common/stylesheets/modules/widgets/testimonials.less';
import '../common/stylesheets/modules/howItWorks/all-media-types-info.less';
import '../common/stylesheets/modules/notfound/not-found.less';
import '../common/stylesheets/modules/popups/paymentMethodInfo.less';
import '../common/stylesheets/modules/signup/bmt-free-trial.less';
import '../common/stylesheets/modules/signup/sign-up.less';
import '../common/stylesheets/modules/survey/survey.less';
import '../common/stylesheets/modules/widgets/full-width-background-widget.less';
import '../common/stylesheets/modules/widgets/stock-item-group-widget-card-design-v2.less';
import '../common/stylesheets/modules/widgets/stock-item-group-widget-card-design.less';
import '../common/stylesheets/modules/widgets/stock-item-group-widget.less';
import '../global/components/storyblocks-collection-browse.less';
import '../global/tools/mixins.less';
import '../graphicstock/components/markup-mixins.less';
import '../graphicstock/modules/expired-cc/expired-cc.less';
import '../graphicstock/modules/home/home.less';
import '../graphicstock/modules/search/search-results-page.less';
import '../graphicstock/modules/search/searchResults.less';
import '../react/builder-io/builder-io.less'


import AppLoader from '../react/AppLoader/AppLoader';
import GenericFAQ from '../react/common/components/GenericFAQ';
import StockItemCollectionContainer from '../react/app/Search/containers/StockItemCollectionContainer';
import PopUpsContainer from '../react/PopUps/PopUpsContainer';
import PortfolioContainer from '../react/ContributorPortfolio/containers/PortfolioContainer';
import PageNotFound from '../react/common/components/PageNotFound';
import ImageSiteConstants from '../react/common/SiteConstants/ImageSiteConstants';
import BuilderClientSideRenderer from '../react/builder-io/BuilderClientSideRenderer';
import EntTrialBanner from "../react/Trials/EntTrialBanner";
import NavApps from "../react/app/NavApps";

AppLoader.load({
  routes: [
    {
      name: 'Collection',
      path: new ImageSiteConstants().getCollectionsRoute(),
      component: StockItemCollectionContainer,
    },
    {
      name: 'Portfolio',
      path: '/images/portfolio/:username',
      component: PortfolioContainer,
    },
    // NOTE: this needs to go last!
    {
      name: 'PageNotFound',
      path: '*',
      component: PageNotFound,
    },
  ],
  auxApps: {
    ...NavApps,
    'pop-up-container': PopUpsContainer,
    'builder-io-wrapper': BuilderClientSideRenderer,
  },
  propBasedApps: {
    genericFrequentlyAskedQuestions: GenericFAQ,
    'entTrialBanner': EntTrialBanner
  },
});

import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import { Search } from '@videoblocks/react-icons';

import AbstractSearchResultsContainer, {
  mapStateToProps,
} from '../../app/Search/containers/AbstractSearchResultsContainer';
import {
  selectCategories,
  selectSearchIsLoading,
  selectSearchIsLoadingAdditionalResults,
  selectPagination,
  selectPortfolioInfo,
  selectSearchOptions,
  selectTotalItemCountForContributor,
  selectUsername,
} from '../../app/Search/selectors/searchSelectors';
import SiteConstants from '../../common/SiteConstants/SiteConstants';
import { getStockLabelForSite, startCase } from '../../common/utils';
import {
  fetchPortfolioResults,
  switchToCategoriesTab,
} from '../actions/PortfolioActions';

import '../../../common/stylesheets/modules/portfolio/portfolio.less';

class PortfolioContainer extends AbstractSearchResultsContainer {
  state = {
    selectedSearchTerm: this.props.selectedSearchFilterOptions.searchTerm,
    selectedTab: this.props.selectedSearchFilterOptions.sort,
    collapseBio: true,
    bioText: null,
  };

  defaultPortfolioWallpaper =
    '/assets/common/images/portfolio/portfolio-wallpaper.jpg';
  defaultPortfolioProfile =
    '/assets/common/images/portfolio/portfolio-profile.png';

  componentDidMount() {
    this.setState({
      bioText: document.getElementsByClassName('portfolio-bio-text')[0],
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // we need to detect the if text is overflowing AFTER the DOM renders and set state
    // to display the "show more" link if necessary
    if (
      !prevState.collapseBio &&
      this.state.collapseBio &&
      this.doesBioTextOverflow()
    ) {
      this.setState({
        collapseBio: true,
      });
    }
  }

  render() {
    return (
      <div className="portfolio-all">
        <div className="portfolio-banner">
          <img
            alt="Wallpaper"
            className="wallpaper-image"
            src={
              this.props.portfolioInfo.wallpaperImgUrl ||
              this.defaultPortfolioWallpaper
            }
            width={1400}
            height={238}
          />
          <a
            href={`${SiteConstants.buildInstance().getPortfolioBasePath()}/${
              this.props.username
            }`}
          >
            <img
              alt="Profile"
              className="profile-image"
              src={
                this.props.portfolioInfo.profileImgUrl ||
                this.defaultPortfolioProfile
              }
              width={160}
              height={160}
            />
          </a>
        </div>

        <div className="portfolio-results">
          {this.getPortfolioInfo()}
          {this.getPortfolioNav()}
        </div>
        {this.renderSearchFilter()}
        {this.renderResults()}
      </div>
    );
  }

  renderSearchFilter() {
    const selectedCategory = this.props.selectedSearchFilterOptions.categories;
    const selectedTab = this.state.selectedTab;

    return (
      <div className={this.getFiltersClasses()}>
        <div className="wrapper">
          {selectedTab !== 'categories' &&
            this.state.selectedSearchTerm !== '' && (
              <span>Search results for "{this.state.selectedSearchTerm}"</span>
            )}

          {selectedTab === 'categories' && selectedCategory && (
            <React.Fragment>
              <button
                onClick={() => this.handleCategorySelected(null)}
                type="button"
                className="btn btn-collection back-button"
              >
                <span>Back to Top Categories</span>
              </button>
              <span>
                Search results in "{this.getCategoryName(selectedCategory)}"
              </span>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }

  getCategoryName(categoryId) {
    const category = this.props.categories.find((category) => {
      return category.id === categoryId;
    });

    return category ? category.name : '';
  }

  getFiltersClasses() {
    return twMerge(
      'results-search-filter',
      this.state.selectedTab === 'categories' &&
        this.props.selectedSearchFilterOptions.categories &&
        'contains-back-button'
    );
  }

  renderResults() {
    if (
      this.state.selectedTab === 'categories' &&
      !this.props.selectedSearchFilterOptions.categories
    ) {
      return this.renderCategoriesDiv();
    } else {
      return super.render();
    }
  }

  renderCategoriesDiv() {
    return (
      <div id="categories-browse">
        <div
          className="categories-container"
          style={{ paddingTop: '0px', paddingBottom: '25px' }}
        >
          {this.props.categories.map((category) => {
            return this.renderSingleCategory(
              category.id,
              category.name,
              category.thumbnailUrl,
              category.count
            );
          })}
        </div>
      </div>
    );
  }

  renderSingleCategory(categoryId, categoryName, categoryUrl, categorySize) {
    return (
      <div className="category-wrapper" key={categoryId}>
        <img
          alt="Category"
          className="category-image"
          src={categoryUrl}
          width={200}
          height={185}
        />
        <div className="category-image-shadow" />
        <div className="category-text">
          <div className="category-text-header">{categoryName}</div>
          <a
            role="button"
            tabIndex={0}
            className="btn btn-default category-text-link"
            onClick={() => {
              this.handleCategorySelected(categoryId);
            }}
            onKeyDown={() => {
              this.handleCategorySelected(categoryId);
            }}
          >
            View Category
          </a>
        </div>
        <div className="category-notes">
          {categorySize}
          <img
            alt="Collections"
            src="/assets/common/images/collections-icon.svg"
            width={16}
            height={12}
          />
        </div>
      </div>
    );
  }

  getCurrentStockItems() {
    if (!this.props.loading || this.props.isLoadingAdditionalResults) {
      return Object.values(this.props.stockItems);
    } else {
      return [];
    }
  }

  getPagination() {
    return this.props.pagination;
  }

  goToPage = (newPageNo) => {
    this.handleFilterChanged('page', newPageNo);

    this.scrollToTop();
  };

  getPortfolioInfo() {
    return (
      <div className="portfolio-info">
        <h1 className="portfolio-title">
          <strong>{this.props.username}</strong>
        </h1>

        <div className="portfolio-stats">
          <span className="stat">{`${
            this.props.totalItemCountForContributor
          } ${startCase(getStockLabelForSite())}`}</span>

          {this.props.portfolioInfo.contributorLocation && (
            <span className="stat">
              <i className="fa fa-map-marker" aria-hidden="true"></i>{' '}
              {this.props.portfolioInfo.contributorLocation}
            </span>
          )}
        </div>

        <div className="portfolio-bio">
          <p className={this.getBioClasses()}>{this.props.portfolioInfo.bio}</p>
          {this.getBioToggleElement()}
        </div>
      </div>
    );
  }

  getBioClasses() {
    return twMerge(
      'portfolio-bio-text',
      this.state.collapseBio && 'collapsed-bio'
    );
  }

  getBioToggleElement() {
    if (
      this.state.bioText &&
      (this.doesBioTextOverflow() || !this.state.collapseBio)
    ) {
      return (
        <a
          role="button"
          tabIndex={0}
          href="#"
          onClick={(e) => this.toggleBioText(e)}
          onKeyDown={(e) => this.toggleBioText(e)}
        >
          see {this.state.collapseBio ? 'more' : 'less'}
        </a>
      );
    } else {
      return null;
    }
  }

  doesBioTextOverflow() {
    return this.state.bioText.scrollWidth > this.state.bioText.offsetWidth;
  }

  toggleBioText = (e) => {
    e.preventDefault();

    this.setState({
      collapseBio: !this.state.collapseBio,
    });
  };

  getPortfolioNav() {
    const { selectedSearchFilterOptions } = this.props;

    const { selectedTab, selectedSearchTerm } = this.state;
    return (
      <div className="portfolio-nav">
        <button
          className={this.getTabClass('most_downloaded')}
          onClick={(e) => this.handleTabChanged(e, 'most_downloaded')}
        >
          Most Downloaded
        </button>
        <button
          className={this.getTabClass('most_recent')}
          onClick={(e) => this.handleTabChanged(e, 'most_recent')}
        >
          Most Recent
        </button>
        <button
          className={this.getTabClass('categories')}
          onClick={(e) => this.handleTabChanged(e, 'categories')}
        >
          Top Categories
        </button>

        {(selectedTab !== 'categories' ||
          selectedSearchFilterOptions.categories) && (
          <form
            className="portfolio-searchForm"
            role="search"
            onSubmit={this.handleSearch}
          >
            <input
              type="text"
              className="search-form-input"
              placeholder="Search"
              onChange={this.handleSearchTermChange}
              value={selectedSearchTerm}
            />
            <button
              type="submit"
              className="search-form-button flex items-center"
              inputMode="search"
            >
              <Search className="w-4 h-4" />
            </button>
          </form>
        )}
      </div>
    );
  }

  handleSearch = (e) => {
    e.preventDefault();
    this.handleFilterChanged('searchTerm', this.state.selectedSearchTerm);
  };

  handleSearchTermChange = (e) => {
    this.setState({
      selectedSearchTerm: e.target.value,
    });
  };

  getTabClass(value) {
    return twMerge('tab', value === this.state.selectedTab && 'active');
  }

  handleFilterChanged = (name, value) => {
    const isCategory = name === 'categories';
    const page = name === 'page' ? value : 1; // Go back to page 1 when any other filter is changed

    // if changing sort, reset categories to null. if changing search term or loading more results, maintain currently category.
    let categories =
      name === 'searchTerm'
        ? this.props.selectedSearchFilterOptions.categories
        : null;

    // if changing category, set the new value
    if (isCategory) {
      categories = value;
    }

    const propertiesToChange = {
      [name]: value,
      page,
      categories,
    };

    const newSearchOptions =
      this.props.selectedSearchFilterOptions.update(propertiesToChange);

    if (isCategory && value === null) {
      this.props.dispatch(switchToCategoriesTab(newSearchOptions));
    } else {
      this.props.dispatch(
        fetchPortfolioResults(this.props.username, newSearchOptions, isCategory)
      );
    }
  };

  handleTabChanged = (e, value) => {
    e.preventDefault();

    if (value !== 'categories' && value !== this.state.selectedTab) {
      this.handleFilterChanged('sort', value);
    } else if (value === 'categories') {
      // we're not firing a request but we still need to update the filters and the url
      const propertiesToChange = {
        sort: 'categories',
        categories: null,
        page: 1,
      };

      const newSearchOptions =
        this.props.selectedSearchFilterOptions.update(propertiesToChange);
      this.props.dispatch(switchToCategoriesTab(newSearchOptions));
    }

    this.setState({
      selectedTab: value,
    });
  };

  handleCategorySelected = (categoryId) => {
    this.handleFilterChanged('categories', categoryId);
  };
}

function mapStateToPropsForPortfolio(state) {
  return _.merge(mapStateToProps(state), {
    pagination: selectPagination(state),
    username: selectUsername(state),
    totalItemCountForContributor: selectTotalItemCountForContributor(state),
    searchOptions: selectSearchOptions(state),
    categories: selectCategories(state),
    portfolioInfo: selectPortfolioInfo(state),
    isLoading: selectSearchIsLoading(state),
    isLoadingAdditionalResults: selectSearchIsLoadingAdditionalResults(state),
  });
}

export default connect(mapStateToPropsForPortfolio)(PortfolioContainer);
